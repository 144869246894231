

/*
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ 
        Test Environment 

        Banner:  This is the main Copy.
        Don't change any code here  

@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ 
                

Last Update: 21032024:1417

Version: V1.6

Modification: 
1. cart page update


Project Details:    
                
# Project Name:   My idea s
# App Code: RNM01052022 
# Tech Stack: 
  Front End --> React 
  BackEnd -->   Node.JS  
  DataBase-->   MongoDB


@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@


Tech Stack:   React + Node.js + MongoDB


===============================================
Reusable Code:

import Apps from            './pages/Apps'
import Network from         './pages/Network'
import Cyber from           './pages/Cyber'



<Route  path="/apps"            element={<Apps/>} />
<Route  path="/network"         element={<Network/>} />
<Route  path="/cyber"           element={<Cyber/>} />


<div className='warning'>Item is already added to your cart</div>

         <Route  path="/addproduct"     element={<AddProduct/>}/>    
import AddProduct from      './pages/AddProduct'
import ProductEditPage from './pages/ProductEditPage';
                            <Route  path="/:id"             element={<ProductEditPage/>} />  
import ProductList from     './pages/ProductList'
           <Route  path="/productlist"     element={<ProductList/>}/> 


/*

Line no 140 

  const res = await axios.get("https://api.myidealtd.co.uk/api/products", {

For Production use -->  api.myidealtd.co.uk
For Testing use    -->  127.0.0.1


Line no 186
                     <Alert variant="danger" onClose={() => setShow(false)} dismissible>          
                        <p class=" text-center h1 container mt-2 ">Item already in cart</p>
                      </Alert>

*/





import React,{useState,useEffect} from 'react';
import {BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import axios from "axios";
import                      './App.css';

import Home from            './pages/Home'

import Pricing from        './pages/Pricing'
import Cart from            './pages/Cart';
import CheckoutSuccess from './pages/CheckoutSuccess';
import NotFound from        './pages/NotFound';



           


import NavbarMain from      './components/NavbarMain.js'
import Navbarcontact from   './components/Navbarcontact.js'
import Footer from          './components/Footer.js'
import Modal_ from          './components/Modal_.js'


import Alert from    'react-bootstrap/Alert';
import Button from   'react-bootstrap/Button';
import Modal from 'react-modal';

const cartFromLocalStorage = JSON.parse(localStorage.getItem("cart") || "[]")



function  App () {

  const [cart,setCart] = useState(cartFromLocalStorage);
  const [show,setShow] = useState(true);
  const [warning,setWarning] = useState(false)
  const [data, setData] = useState([]);
  const [qty,setQty] = useState(1);

  const [email,setEmail] = useState('');
  const [password,setPassword] = useState('');
  
  const [modaIsOpen,setModalIsOpen]= useState(true);
  
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
    Overlay: {
      position: 'fixed',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      background: 'rgba(49,49,50,0.8)',
  
     
    },
  };
  
  const fetchProductList = async ()=> {

    const res = await axios.get("https://api.myidealtd.co.uk/api/products", {
      headers: {
          "Content-Type": "application/json"
               }
     });
     setData(res.data.Products);
  } 

  


  const addtocart = (item)=>{

     let isPresent = false ;
 
      cart.forEach((product)=> {
        if (item._id === product._id)
        isPresent = true ;
      })
      if (isPresent){
        setWarning(true);
   
      
        return ;
      }
      setCart([...cart,item]); 
  }

  
 

  
  const item_remove =(_id)=> {
        const new_array_items = cart.filter((item)=> item._id !== _id);
        localStorage.setItem("cart",JSON.stringify(new_array_items)) 
        setCart(new_array_items);
  }


  const emptycart = async ()=> {
    const emptylocalStorage = localStorage.removeItem("cart");
    setCart([]);
    
  }


  useEffect(()=> {
 
    localStorage.setItem("cart",JSON.stringify(cart));

 },[cart]);

  return (



          <Router>
                <Navbarcontact/>
                <NavbarMain setShow={setShow} cart={cart}/>
                {
			            warning &&    
                 
                      <Modal isOpen={modaIsOpen}
                             style={customStyles}               
                      >
               
                        <h1>Item is already added to your cart</h1>
             
             
                        <button class="_btn mt-5" onClick={()=>setModalIsOpen(false)}>Close</button>
                      </Modal>
   
		            }
                      <Routes>
                            <Route  path="/"                 element={<Home  cart={cart} emptycart={emptycart}/>}/>
                            <Route  path="/pricing"          element={<Pricing      addtocart={addtocart} fetchProductList={fetchProductList}  data={data}  />}/> 
                            <Route  path="/cart"             element={<Cart  cart={cart} setCart={setCart} item_remove={item_remove} qty={qty}   />}/>
                            <Route  path="/checkout-success" element={<CheckoutSuccess/>} />
                            <Route  path="*"                 element={<NotFound/>}/>  
                    

                      </Routes>     
                  <Footer/>              
          </Router>
  )
}

export default App



                 

